import { CustomInput } from '../inputs/input';

export class ModalInfo {
    HeaderTitle: string;
    HeaderImage: string;
    ModalId: string;
    Content: Array<CustomInput<Object>>
    modalSize: string;
    private sendButton: boolean = true;
    private closeButton: boolean = true;

    setModalSizeXl(): void {
        this.modalSize = "modal-lg"
    }

    setSendButtonVisible(value: boolean): void {
        this.sendButton = value;
    }

    setCloseButtonVisible(value: boolean): void {
        this.closeButton = value;
    }

    isSendButtonVisible(): Boolean {
        return this.sendButton
    }

    isCloseButtonVisible(): Boolean {
        return this.closeButton
    }
}
