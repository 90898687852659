export class EmailAddress {
    
    private address: string;

    constructor(value: string) {
        if (!this.isEmail(value)) {
            throw new Error("E-mail inválido");
        }
        this.address = value;
    }

    private isEmail(value: string): boolean {
        let regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        return regexp.test(value)
    }

    getAddress(): string {
        return this.address;
    }
}